import { Container, Col, Row } from "react-bootstrap";
import React from "react";
import ExternalLink from "../../components/ExternalLink";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Banner from "../../components/banner";
import MaternalHealthImg from "../../assets/images/banner-homepage.jpg";

const MaternalHealth = () => {
  return (
    <>
      <Layout page='article'>
        <SEO
          title='Maternal Health Innovation'
          description='Thriving and Striving for Better Birth Outcomes'
          image='../images/banner-homepage.jpg'
          keywords={[``]}
        />
        <Banner title='Podcasts' subTitle={``} titleColor='#FFFFFF' subTitleColor='#FFFFFF' hasArrow={true} reverse={true} />
        <section className='center-flex justify-center'>
          <Container className='post-container'>
            <Row>
              <Col className='post' lg='12'>
                <h1>Maternal Health Innovation</h1>
                <hr className='horizontalRule dark' />
                <div className='post-content'>
                  <p>
                    The Maternal Health Learning and Innovation Center, a network of maternal health equity experts and organizations, are working to
                    make real change for women and birthing people in the U.S. The Maternal Health Innovation Podcast is a series of episodes
                    connecting around culture, lifting voices and stories, exploring innovations in maternal care and discussing ways we can center
                    equity in the way we approach our health and wellness. Learn at <ExternalLink href='https://www.MaternalHealthLearning.org'><a>MaternalHealthLearning.org</a>
                    </ExternalLink>
                    .
                  </p>
                  <p>
                    <ExternalLink href='https://pod.link/1583121442/episode/e070359046cfc260ab14d8207e7f79c5'>
                      <a>Bonus Episode, featuring Jacque Caglia, Director of Learning Communications and U.S. programs at Merck for Mothers.</a>
                    </ExternalLink>
                  </p>

                  <h4>From Research to Action: Merck for Mothers' Evidence-based Approach to Maternal Health </h4>
                  <p>
                    In this special bonus episode, Dr. Kristin Tully interviews Jacque Caglia, the Director of Learning Communications and U.S. programs
                    at Merck for Mothers. The episode focuses on Merck for Mothers' commitment to eliminating maternal mortality globally and their
                    approach to improving maternal health outcomes through grant-making and strategic collaborations. Jacque shares her personal and
                    professional experiences, emphasizing the importance of community involvement and deep listening to address maternal health
                    challenges. The conversation highlights Merck for Mothers' global initiative to improve access to safe, high-quality, and
                    respectful care during pregnancy and childbirth. They discuss the organization's evidence-based approach, including publishing
                    their research compendium, which presents key findings in a digestible and actionable format. The episode also explores Merck for
                    Mothers' collaboration with the Black Mamas Matter Alliance and their Safer Childbirth Cities Initiative, which supports
                    community-based organizations in addressing racial and ethnic disparities in maternal health outcomes.{" "}
                    <ExternalLink href='https://pod.link/1583121442/episode/e070359046cfc260ab14d8207e7f79c5'>
                      <a>Tune in</a>
                    </ExternalLink>{" "}
                    to learn more about Merck for Mothers' efforts to shift the maternal health landscape and promote equity in maternal care.
                  </p>
                  <p>06 Jun 2023 · 42 minutes</p>
                </div>
                <Row>
                  <Col lg='4'>
                    <img src={MaternalHealthImg} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div className='arrow-down reverse centered'></div>
        </section>
        <section className='darkBlueColorBkgd'>
          <Container>
            <Row>
              <Col xs='12' sm='12' md='12' lg='12'>
                <div className='spacer60'></div>
                <h2 className='whiteColor'>About Safer Childbirth Cities</h2>
                <p className='whiteColor'>
                  The Safer Childbirth Cities initiative aims to support community-based organizations in U.S. cities with a high burden of maternal
                  mortality and morbidity to implement evidence-based interventions and innovative approaches to reverse the country’s maternal health
                  trends and directly tackle racial inequities in maternal health outcomes. Our vision is for cities to become safer &mdash; and more
                  equitable &mdash; places to give birth.
                </p>
                <div className='spacer60'></div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default MaternalHealth;
